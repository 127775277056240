import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import config from '../../config.json';
import { Tab, TabGroup, TabList, TabPanel, Field, Label, Switch, Input } from '@headlessui/react';
import clsx from 'clsx';
import useAnalytics from '../analytics/analytics';
import embedded from '../../embedded.bundle.js';
import CopyCodeModal from './CopyCodeModal';

interface DeployAgentProps {
    agId: string;
    draftId: string | undefined;
    refresh: boolean;
    setRefreshPreview: (refresh: boolean) => void;
}

const DeployAgent: React.FC<DeployAgentProps> = ({ agId, draftId, refresh, setRefreshPreview }) => {
    const { captureEvent } = useAnalytics();
    const [togglePreview, setTogglePreview] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [liveAgent, setLiveAgent] = useState<boolean>(false);
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const copyDeployCode = (e: any, platform: string) => {
        e.preventDefault();
        const el = document.createElement('textarea');
        if (platform === "website") {
            el.value = `
            <script>
                (function() {
                    function initAgent() {
                        window.Agent.initAgentChat('ai-assistant', {
                            agentId: '${agId}'
                        });
                    }
                    var script = document.createElement('script');
                    script.src = '${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/embedded.bundle.js';
                    script.async = true;
                    script.onload = initAgent;
                    document.body.appendChild(script);
                })();
            </script>
            `;
        } else if (platform === "aimdoc") {
            el.value = `${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/agent/${agId}`;
        }
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success('Code copied to clipboard', toastConfig);
        captureEvent('copy_deploy_code', {
            agent_id: agId,
            platform: platform
        });
    };

    const generatePreview = (e: any) => {
        e.preventDefault();

        if (!agId) {
            toast.error('Please provide a valid website URL and Agent ID', toastConfig);
            return;
        }

        const encodedUrl = encodeURIComponent(previewUrl);
        const previewLink = `${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/agent-embed-preview/${liveAgent ? agId : draftId}?url=${encodedUrl}`;

        window.open(previewLink, '_blank');
    };

    useEffect(() => {
        if (agId && selectedIndex === 1) {

            const existingWrapper = document.querySelector('.wrapper-transition');
            if (existingWrapper) {
              existingWrapper.remove();
            }

            setTimeout(() => {
                const previewUrl = `${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/agent-embed-preview/${draftId}`;
                const container = document.getElementById('preview-ai-assistant');
                if (container) {
                    container.innerHTML = '';
                    const iframe = document.createElement('iframe');
                    iframe.src = previewUrl;
                    iframe.style.width = '100%';
                    iframe.style.height = '100%';
                    iframe.style.border = 'none';
                    iframe.style.borderRadius = '10px';
                    container.appendChild(iframe);
                }
            }, 0);

            return () => {
                const container = document.getElementById('preview-ai-assistant');
                if (container) {
                    container.innerHTML = '';
                }
            };
        }
    }, [selectedIndex, draftId, agId]);

    useEffect(() => {
        if (selectedIndex === 1) {
            const url = new URL(window.location.href);
            url.searchParams.set('preview', 'true');
            window.history.replaceState({}, '', url.toString());
        } else {
            const url = new URL(window.location.href);
            url.searchParams.delete('preview');
            window.history.replaceState({}, '', url.toString());
        }
    }, [selectedIndex]);

    return (
        <div className={`flex flex-col ${selectedIndex === 1 ? 'w-[550px] max-w-[550px]' : 'w-[25%] max-w-[25%]'} 
            bg-slate-50/50 p-7 scrollbar-thin border-l border-slate-200 dark:bg-slate-800 dark:border-slate-700
            transition-all duration-300 ease-in-out`}>
            <TabGroup className="h-full w-full" selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <TabList className="flex gap-4 justify-center">
                    {agId && (
                        <Tab className="flex flex-row items-center justify-between rounded-md py-1 px-3 text-sm/6 font-semibold bg-slate-100 
                                        text-slate-600 border border-slate-200 shadow-sm focus:outline-none data-[selected]:bg-slate-200 data-[selected]:text-slate-700 
                                        dark:data-[selected]:text-slate-50 data-[hover]:bg-slate-300 dark:data-[hover]:bg-slate-600 transition duration-200 dark:bg-slate-700 dark:data-[selected]:bg-slate-600 
                                        dark:border-slate-600 dark:text-slate-300">
                            <div className="mr-2 text-lg font-semibold">
                                Deploy
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 stroke-slate-600 dark:stroke-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
                            </svg>
                        </Tab>
                    )}
                    <Tab className="flex flex-row items-center justify-between rounded-md py-1 px-3 text-sm/6 font-semibold bg-slate-100 
                                        text-slate-600 border border-slate-200 shadow-sm focus:outline-none data-[selected]:bg-slate-200 data-[selected]:text-slate-700 
                                        dark:data-[selected]:text-slate-50 data-[hover]:bg-slate-300 dark:data-[hover]:bg-slate-600 transition duration-200 dark:bg-slate-700 dark:data-[selected]:bg-slate-600 
                                        dark:border-slate-600 dark:text-slate-300"
                    >
                        <div className="mr-2 text-lg font-semibold">
                            Preview
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 stroke-slate-600 dark:stroke-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </Tab>
                </TabList>
                <TabPanel className="flex flex-col w-full max-w-full">
                    <div className="flex flex-col w-full items-center mt-8 justify-between p-3 rounded-lg border border-slate-200 bg-white dark:bg-slate-900 dark:border-slate-700">
                        <div className="flex flex-col w-full justify-between">
                            <label className="text-lg font-medium text-slate-900 dark:text-slate-100">
                                Website Embed Code
                            </label>
                            <label className="block text-sm font-light leading-6 text-slate-600 mt-1 dark:text-slate-200">
                                Get the code to embed your agent on your website.
                            </label>
                        </div>
                        <div className="text-center py-2 px-4 mt-4 border w-full rounded-md hover:cursor-pointer font-medium text-blue-900 hover:bg-blue-200 bg-blue-100 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-100 dark:hover:bg-slate-700 transition duration-200"
                            onClick={() => setIsCodeModalOpen(true)}>
                            View Embed Code
                        </div>
                    </div>
                    <CopyCodeModal
                        isOpen={isCodeModalOpen}
                        setIsOpen={setIsCodeModalOpen}
                        agId={agId}
                        onCopy={copyDeployCode}
                    />
                </TabPanel>
                <TabPanel className="flex flex-col h-[98%] max-w-full">
                    <div className="flex flex-col dark:bg-slate-800 h-full w-full items-center">
                        <Field className='mt-6 flex justify-center items-center'>
                            <Label className={`text-sm/6 font-bold dark:text-slate-100 mr-2 ${togglePreview ? 'text-slate-400 dark:text-slate-400' : 'text-slate-700 dark:text-slate-50'}`}>Widget</Label>
                            <Switch
                                checked={togglePreview}
                                onChange={setTogglePreview}
                                className={`${togglePreview ? 'bg-slate-600 dark:bg-slate-500' : 'bg-slate-200 dark:bg-slate-700'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span
                                    className={`${togglePreview ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                            <Label className={`text-sm/6 font-bold dark:text-slate-100 ml-2 ${togglePreview ? 'text-slate-700 dark:text-slate-50' : 'text-slate-400 dark:text-slate-400'}`}>Website</Label>
                        </Field>
                        {!togglePreview && (
                            <div id="preview-ai-assistant" className="w-full h-full mt-8 rounded-lg overflow-hidden">
                                {/* iframe will be inserted here by useEffect */}
                            </div>
                        )}
                        {togglePreview && (
                            <div className="flex flex-col w-full items-center mt-8 justify-between p-3 rounded-lg border border-slate-200 bg-white dark:bg-slate-900 dark:border-slate-700">
                                <div className="flex flex-col w-full justify-between">
                                    <label className="text-lg font-medium text-slate-900 dark:text-slate-100">
                                        Preview on Website
                                    </label>
                                    <label className="block text-sm font-light leading-6 text-slate-600 mt-1 dark:text-slate-200">
                                        Enter your website URL to preview the agent.
                                    </label>
                                    <Input
                                        className={clsx(
                                            'block w-full rounded-md border border-slate-200 py-1 px-3 text-sm/6 text-slate-900 mt-2 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-100',
                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                        )}
                                        onChange={(e) => setPreviewUrl(e.target.value)}
                                        required={true}
                                    />
                                    <Field className='mt-4 flex justify-start items-center'>
                                        <Label className={`text-sm/6 font-bold dark:text-slate-100 mr-2 ${liveAgent ? 'text-slate-400 dark:text-slate-400' : 'text-slate-700 dark:text-slate-50'}`}>Draft</Label>
                                        <Switch
                                            checked={liveAgent}
                                            onChange={setLiveAgent}
                                            className={`${liveAgent ? 'bg-slate-600 dark:bg-slate-500' : 'bg-slate-200 dark:bg-slate-700'
                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span
                                                className={`${liveAgent ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                        <Label className={`text-sm/6 font-bold dark:text-slate-100 ml-2 ${liveAgent ? 'text-slate-700 dark:text-slate-50' : 'text-slate-400 dark:text-slate-400'}`}>Live</Label>
                                    </Field>
                                </div>
                                <div onClick={generatePreview} className="text-center py-2 px-4 mt-4 border w-full rounded-md hover:cursor-pointer font-medium text-blue-900 hover:bg-blue-200 bg-blue-100 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-100 dark:hover:bg-slate-700 transition duration-200">
                                    Generate Preview
                                </div>
                            </div>
                        )}
                    </div>
                </TabPanel>
            </TabGroup>
        </div>
    );
}

export default DeployAgent;