import React, { useContext, useRef, useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import UserCircle from '../user/UserCircle';
import { ReactComponent as Logo } from '../../logo_main.svg';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import { set } from 'lodash';
import { supabase } from '../supabaseClient'
import Spinner from '../documentation/Spinner';
import { useUser } from '../user/UserContext';
import Upgrade from '../modals/Upgrade'
import useAnalytics from '../analytics/analytics';
import { conLog } from '../utils/conLog';
import { Dialog, Transition } from '@headlessui/react'
import { AimdocComponentContext } from '@aimdoc/sdk-react';

const toastConfig: ToastOptions = {
   position: "top-right",
   autoClose: 2000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: false,
   draggable: true,
   progress: undefined,
   theme: "light",
};

const NavBar = ({ selectedItem }: any) => {
   const [settingDropdown, setSettingDropdown] = useState(false);
   const [addDropdown, setAddDropdown] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const [helpModal, setHelpModal] = useState(false);
   const [feedback, setFeedback] = useState('');
   const [emails, setEmails] = useState<string[]>([]);
   const [addNewEmail, setAddNewEmail] = useState(false);
   const [message, setMessage] = useState('');
   const [loading, setLoading] = useState(false);
   const { get, getStream, post, postStream, put, patch, remove } = useApi();
   const [fullMenu, showFullMenu] = useState(false);
   const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
   const [trialExpired, setTrialExpired] = useState(false);

   const navigate = useNavigate();
   const { user, organization, refreshOrganization } = useUser();
   const { captureEvent } = useAnalytics();

   // get param called session_id
   const search = new URLSearchParams(window.location.search);
   const sessionId = search.get('session_id');
   conLog('sessionId:', sessionId)
   // refresh organization when session id is present
   useEffect(() => {
      if (sessionId) {
         refreshOrganization();
      }
   }, [sessionId]);

   const modalRef = useRef(null);
   useOutsideAlerter(modalRef, () => toggleModal());

   const toggleModal = () => {
      setModalVisible(!modalVisible);
   };

   const feedbackRef = useRef(null);
   useOutsideAlerter(feedbackRef, () => toggleHelpModal());

   const toggleHelpModal = () => {
      setHelpModal(!helpModal);
   };

   const settingsRef = useRef(null);
   useOutsideAlerter(settingsRef, () => toggleDropdown());

   const toggleDropdown = () => {
      setSettingDropdown(!settingDropdown);
   };

   const addDropRef = useRef(null);
   useOutsideAlerter(addDropRef, () => toggleAdd());

   const toggleAdd = () => {
      setAddDropdown(!addDropdown);
   };

   const handleLogout = async () => {
      await supabase.auth.signOut();
      captureEvent('logged_out', {});
      navigate('/login');
   };

   const handleSendInvite = async () => {
      setLoading(true);
      try {
         for (const email of emails) {
            if (!validateEmail(email)) {
               toast.error(`Invalid email: ${email}`, toastConfig);
               return;
            }
         }
         const response = await post('/invite/', { emails, message });
         setEmails([]);
         setMessage('');
         toggleModal();
         toast.success('Invites sent!', toastConfig);
         captureEvent('sent_invite', {
            emails: emails,
            message: message
         });
      } catch (error) {
         console.error('Error sending invite:', error);
         toast.error(`Error sending invite. Please contact benjskoog@gmail.com`, toastConfig);
      }
      setLoading(false);
   };

   const handleSubmitFeedback = async () => {
      setLoading(true);
      try {
         const response = await post('/feedback/', { feedback: feedback });
         setMessage('');
         setHelpModal(false);
         toast.success('Feedback sent!', toastConfig);
         captureEvent('feedback_submitted', {
            feedback: feedback
         });
      } catch (error) {
         console.error('Error sending feedback:', error);
         toast.error(`Error sending feedback. Please contact`)
      }
   };

   const icon1: any = useRef(null);
   const menu1: any = useRef(null);
   const icon2: any = useRef(null);
   const menu2: any = useRef(null);

   const showMenu1 = () => {
      icon1.current.classList.toggle("rotate-180");
      menu1.current.classList.toggle("hidden");
   };

   const showMenu2 = () => {
      icon2.current.classList.toggle("rotate-180");
      menu2.current.classList.toggle("hidden");
   };

   const validateEmail = (email: string) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
   };

   const setCurrentEmail = (value: any, index: any) => {
      const newEmails = [...emails];
      newEmails[index] = value;
      setEmails(newEmails);
   };

   const calculateRemainingDays = (trialEndDate: string) => {
      const endDate = new Date(trialEndDate);
      const currentDate = new Date();
      const diffTime = endDate.getTime() - currentDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0;
   };

   useEffect(() => {
      if (sessionId || trialExpired) {
         setUpgradeModalVisible(true);
      }
   }, [sessionId, trialExpired]);

   useEffect(() => {
      if (organization && organization.license_type === 'trial' && organization.trial_end_date && calculateRemainingDays(organization.trial_end_date) <= 0) {
         setTrialExpired(true);
      }
   }, [organization]);

   return (
      <>
         <div className="dark:bg-slate-800">
            <div className={`transition-all duration-20 xl:translate-x-0 flex justify-start items-start h-screen w-full flex-col sm:w-64 dark:bg-slate-950`}>
               <aside id="sidebar" className={`fixed hidden z-20 h-full top-0 left-0 flex lg:flex flex-shrink-0 flex-col tr</svg>ansition-all duration-20 w-64 dark:bg-slate-950`}>
                  <div className="relative flex-1 flex flex-col min-h-0 border-r border-slate-200 dark:border-slate-800 bg-white dark:bg-slate-950 pt-0 transition-all duration-500">
                     <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto transition-all duration-500 dark:bg-slate-950">
                        <a href="/" className="flex items-center w-full">
                           <Logo className="h-7 mr-2"></Logo>
                        </a>
                        <div className="mt-4 flex-1 px-3 bg-white dark:bg-slate-950 divide-y space-y-1 transition-all duration-500 dark:divide-slate-400">
                           <ul className="space-y-2 pb-2">
                              <li className="">
                                 <a href="/" className={`${selectedItem === '' || selectedItem === '/' || selectedItem === 'home' ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 hover:bg-slate-100 font-semibold rounded-lg hover:bg-font-medium group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                    <span className="ml-3">Home</span>
                                 </a>
                              </li>
                           </ul>
                           <ul className="space-y-2 pb-2 pt-3">                      
                              <a id="agents" href="/agents" className={`${selectedItem.includes('agents') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                                 </svg>
                                 <span className="ml-3">Agents</span>
                                 <AimdocComponentContext
                                 componentDescription="This button navigates to the Agents list page. You'll need to here to do anything agent related, such as training an agent on a website or file, updating the agent's form (like adding a new field), modifying the agent's persona or goal, customizing the agent's logo or icon, or creating a new agent."
                                 componentName="Agents"
                              />        
                              </a>
                              <a id="leads" href="/leads" className={`${selectedItem.includes('leads') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>

                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" />
                                 </svg>

                                 <span className="ml-3">Leads</span>
                              </a>
                              <li>
                                 <a id="sessions" href="/sessions" className={`${selectedItem.includes('sessions') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                    </svg>
                                    <span className="ml-3 flex-1 whitespace-nowrap">Sessions</span>
                                 </a>
                              </li>
                              <li>
                                 <a id="analytics" href="/analytics" className={`${selectedItem.includes('analytics') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                                    </svg>
                                    <span className="ml-3 flex-1 whitespace-nowrap">Analytics</span>
                                 </a>
                              </li>
                           </ul>
                           <ul className="space-y-2 pb-2 pt-3">
                              {/* <li>
                        <form action="#" method="GET" className="lg:hidden">
                           <label htmlFor="mobile-search" className="sr-only">Search</label>
                           <div className="relative">
                              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                 <svg className="w-5 h-5 text-slate-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                                 </svg>
                              </div>
                              <input type="text" name="email" id="mobile-search" className="bg-slate-50 border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-cyan-600 focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search"></input>
                           </div>
                        </form>
                     </li> */}
                              <a id="documents" href="/documents" className={`${selectedItem.includes('documents') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                 </svg>
                                 <span className="ml-3">Files</span>
                              </a>
                              <a id="questions" href="/questions" className={`${selectedItem.includes('questions') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                 </svg>
                                 <span className="ml-3">Questions</span>
                              </a>
                              <a id="websites" href="/websites" className={`${selectedItem.includes('websites') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                 </svg>
                                 <span className="ml-3">Websites</span>
                              </a>
                              <li>
                                 <a id="integrations" href="/integrations" className={`${selectedItem.includes('integrations') ? 'bg-slate-50 border border-slate-100 dark:bg-slate-800' : 'border border-white'} text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 dark:hover:bg-slate-700 dark:text-slate-200 dark:border-none`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                       <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z" />
                                    </svg>
                                    <span className="ml-3 flex-1 whitespace-nowrap">Integrations</span>
                                 </a>
                              </li>
                           </ul>
                           <div className="space-y-2 pt-2">
                              <a onClick={handleLogout} className="text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 flex items-center p-2 group transition-all duration-500 hover:cursor-pointer dark:hover:bg-slate-600 dark:text-slate-200 dark:border-none">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                 </svg>
                                 <span className="ml-3 flex-1 whitespace-nowrap">Sign Out</span>
                              </a>
                              <a onClick={() => toggleHelpModal()} target="_blank" className="text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 hover:cursor-pointer dark:hover:bg-slate-600 dark:text-slate-200 dark:border-none">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                                 </svg>
                                 <span className="ml-3">Help</span>
                              </a>
                              <a onClick={() => setModalVisible(true)} target="_blank" className="text-sm text-slate-900 font-semibold rounded-lg hover:bg-slate-100 group transition-all duration-500 flex items-center p-2 hover:cursor-pointer dark:hover:bg-slate-600 dark:text-slate-200 dark:border-none">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-500 flex-shrink-0 dark:stroke-slate-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                 </svg>
                                 <span className="ml-3 flex-shrink-0">Invite Teammates</span>
                              </a>
                           </div>
                        </div>
                        <div className="flex flex-col px-4 items-center rounded-lg bg-white dark:bg-slate-950 overflow-hidden">
                           {organization && organization.license_type !== 'business' && loading === false && (
                              <div onClick={() => setUpgradeModalVisible(true)} className="flex flex-row justify-between items-center h-full w-full px-4 py-2 text-sm text-blue-500 border border-blue-200 bg-blue-50 hover:cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-800 rounded-lg font-bold dark:bg-blue-900 dark:border-blue-800 dark:text-blue-100 transition duration-200">
                                 <div className="flex flex-col w-full justify-between">
                                    <div>
                                       Upgrade
                                    </div>
                                    {organization && organization.license_type === 'trial' && organization.trial_end_date && (
                                       <div className="text-xs font-light text-slate-700 dark:text-slate-300 mr-4">
                                          {calculateRemainingDays(organization.trial_end_date)} days left in trial
                                       </div>
                                    )}
                                 </div>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 shrink-0">
                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                 </svg>
                              </div>
                           )}
                           {(upgradeModalVisible) && (
                              <Upgrade showModal={upgradeModalVisible} setShowModal={setUpgradeModalVisible} sessionId={sessionId} trialExpired={trialExpired} />
                           )}
                        </div>
                        <div className="px-4 mt-4">
                           <div className="flex flex-row px-2 justify-between py-2 border border-slate-200 rounded-lg items-start dark:border-slate-700">
                              <div className="space-y-2">
                                 <UserCircle />
                                 <div className="text-xs text-slate-600 dark:text-slate-200">
                                    {user && user.email}
                                 </div>
                              </div>
                              <AimdocComponentContext
                                 componentDescription="The button below opens the settings pop up. These are user profile settings, not agent settings. Only click here for things like updating notifications, first name, last name, or organization name."
                                 componentName="Setting Gear Icon"
                              />        
                              <button onClick={toggleDropdown} className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-400 hover:stroke-slate-500 hover:cursor-pointer dark:stroke-slate-300 dark:hover:stroke-slate-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                 </svg>
                              </button>
                           </div>
                           {settingDropdown && (
                              <div ref={settingsRef} className="absolute left-[48px] bottom-[100px] mt-2 w-48 bg-white dark:bg-slate-600 shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none rounded-lg" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex={-1}>
                                 <Link to="/user-settings" onClick={toggleDropdown} className="block px-4 py-2 text-sm text-slate-700 dark:text-slate-300 dark:hover:bg-slate-700 hover:bg-slate-100" role="menuitem" tabIndex={-1} id="user-menu-item-0">
                                    Settings
                                 </Link>
                                 <Link to="/privacy" className="block px-4 py-2 text-sm text-slate-700 dark:text-slate-300 dark:hover:bg-slate-700 hover:bg-slate-100" role="menuitem" tabIndex={-1} id="user-menu-item-2">
                                    Privacy Policy
                                 </Link>
                                 <Link to="/logout" onClick={handleLogout} className="block px-4 py-2 text-sm text-slate-700 dark:text-slate-300 dark:hover:bg-slate-700 hover:bg-slate-100" role="menuitem" tabIndex={-1} id="user-menu-item-3">
                                    Sign out
                                 </Link>
                                 {/* ... other links */}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </aside>
               <div className="bg-slate-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
            </div>
            {modalVisible && (
               <Transition appear show={modalVisible} as={Fragment}>
                  <Dialog as="div" className="relative z-40" onClose={() => setModalVisible(false)}>
                     <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                     >
                        <div className="fixed inset-0 bg-black/25" />
                     </Transition.Child>

                     <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                           <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                           >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                 <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-slate-900 mb-4 dark:text-slate-100"
                                 >
                                    Invite members to your team!
                                 </Dialog.Title>

                                 <div className="mt-2 space-y-4">
                                    <input
                                       type="text"
                                       value={emails[0]}
                                       onChange={(e) => setCurrentEmail(e.target.value, 0)}
                                       placeholder="ben@xyz.com"
                                       className={`w-full border border-slate-300 rounded-lg p-2 
                                          ${!validateEmail(emails[0]) && emails[0] ? 'border-red-500 dark:border-red-700' : ''} 
                                          dark:bg-slate-600 dark:border-slate-800 dark:text-slate-200`}
                                    />
                                    <input
                                       type="text"
                                       value={emails[1]}
                                       onChange={(e) => setCurrentEmail(e.target.value, 1)}
                                       placeholder="angela@xyz.com"
                                       className={`w-full border border-slate-300 rounded-lg p-2
                                          ${!validateEmail(emails[1]) && emails[1] ? 'border-red-500 dark:border-red-700' : ''}
                                          dark:bg-slate-600 dark:border-slate-800 dark:text-slate-200`}
                                    />
                                    <input
                                       type="text"
                                       value={emails[2]}
                                       onChange={(e) => setCurrentEmail(e.target.value, 2)}
                                       placeholder="qamar@xyz.com"
                                       className={`w-full border border-slate-300 rounded-lg p-2
                                          ${!validateEmail(emails[2]) && emails[2] ? 'border-red-500 dark:border-red-700' : ''}
                                          dark:bg-slate-600 dark:border-slate-800 dark:text-slate-200`}
                                    />
                                    <textarea
                                       value={message}
                                       onChange={(e) => setMessage(e.target.value)}
                                       placeholder="Optional message..."
                                       rows={3}
                                       className="w-full border border-slate-300 rounded-lg p-2 
                                          dark:bg-slate-600 dark:border-slate-800 dark:text-slate-200"
                                    />
                                 </div>

                                 <div className="mt-6 flex justify-end gap-3">
                                    <button
                                       type="button"
                                       disabled={loading}
                                       className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                       onClick={handleSendInvite}
                                    >
                                       {loading ? (
                                          <>
                                             <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-900 dark:text-slate-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg>
                                             Sending...
                                          </>
                                       ) : (
                                          'Send Invites'
                                       )}
                                    </button>
                                    <button
                                       type="button"
                                       disabled={loading}
                                       className="inline-flex justify-center rounded-md border border-slate-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-800 dark:text-slate-100 dark:border-slate-600 dark:hover:bg-slate-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                       onClick={() => setModalVisible(false)}
                                    >
                                       Cancel
                                    </button>
                                 </div>
                              </Dialog.Panel>
                           </Transition.Child>
                        </div>
                     </div>
                  </Dialog>
               </Transition>
            )}
            {helpModal && (
               <div className="grid place-content-center fixed inset-0 bg-slate-600 bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-40">
                  {loading && (
                     <div className="fixed inset-0 flex items-center justify-center z-50 bg-slate-500 bg-opacity-50">
                        <div className="p-4 rounded-lg">
                           <Spinner />
                        </div>
                     </div>
                  )}
                  <div ref={feedbackRef} className="relative w-full h-full bg-white rounded-xl p-8 dark:bg-slate-800">
                     <div className="flex flex-col">
                        <h2 className="text-xl font-bold mb-2 dark:text-slate-200">Submit feedback or ask questions below!</h2>
                        <p className="text-sm font-light text-slate-500 mb-4 dark:text-slate-300">
                           We will get back to you shortly!
                        </p>
                        <textarea
                           value={feedback}
                           onChange={(e) => setFeedback(e.target.value)}
                           placeholder="Feedback or questions..."
                           className={`border border-slate-300 rounded-lg p-2 mb-4 dark:bg-slate-600 dark:border-slate-800 dark:text-slate-200`}
                           rows={4} // Set the number of rows to display
                           cols={40} // Set the number of columns to display
                        />
                     </div>
                     <div className="flex justify-center mt-4">
                        <button onClick={handleSubmitFeedback} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                           Submit
                        </button>
                     </div>
                  </div>
               </div>
            )}

         </div>
         <script async defer src="https://buttons.github.io/buttons.js"></script>
      </>
   );
};

export default NavBar;