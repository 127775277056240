import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProductDemoStepModal from './ProductDemoStepModal';

interface ProductDemoModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onSave: (demo: { name: string; steps: string[] }) => void;
    initialDemo?: { name: string; steps: string[] };
    isEditing: boolean;
}

export default function ProductDemoModal({
    isOpen,
    closeModal,
    onSave,
    initialDemo = { name: '', steps: [] },
    isEditing
}: ProductDemoModalProps) {
    const [demoName, setDemoName] = useState('');
    const [demoSteps, setDemoSteps] = useState<string[]>([]);
    const [isStepModalOpen, setIsStepModalOpen] = useState(false);
    const [editingStepIndex, setEditingStepIndex] = useState<number | null>(null);

    useEffect(() => {
        if (isOpen) {
            setDemoName(initialDemo.name);
            setDemoSteps(initialDemo.steps);
        }
    }, [isOpen]);

    const handleStepSave = (step: string) => {
        if (editingStepIndex !== null) {
            const updatedSteps = [...demoSteps];
            updatedSteps[editingStepIndex] = step;
            setDemoSteps(updatedSteps);
        } else {
            setDemoSteps([...demoSteps, step]);
        }
        setEditingStepIndex(null);
    };

    const handleEditStep = (index: number) => {
        setEditingStepIndex(index);
        setIsStepModalOpen(true);
    };

    const handleRemoveStep = (index: number) => {
        const updatedSteps = demoSteps.filter((_, i) => i !== index);
        setDemoSteps(updatedSteps);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(demoSteps);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setDemoSteps(items);
    };

    const handleSave = () => {
        if (demoName.trim() && demoSteps.length > 0) {
            onSave({ name: demoName.trim(), steps: demoSteps });
            closeModal();
        }
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                                    >
                                        {isEditing ? 'Edit Product Demo' : 'Create Product Demo'}
                                    </Dialog.Title>

                                    <div className="mt-4">
                                        <input
                                            type="text"
                                            value={demoName}
                                            onChange={(e) => setDemoName(e.target.value)}
                                            placeholder="Enter demo name..."
                                            className="w-full rounded-md border border-slate-300 px-3 py-2 text-sm dark:bg-slate-700 dark:border-slate-600 dark:text-slate-100"
                                        />
                                    </div>

                                    <div className="mt-6 space-y-4">
                                        <button
                                            onClick={() => setIsStepModalOpen(true)}
                                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm transition-colors duration-200"
                                        >
                                            <PlusIcon className="w-4 h-4 mr-2" />
                                            Add Step
                                        </button>

                                        {demoSteps.length > 0 ? (
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="demo-steps">
                                                    {(provided) => (
                                                        <ul 
                                                            {...provided.droppableProps} 
                                                            ref={provided.innerRef}
                                                            className="space-y-2"
                                                        >
                                                            {demoSteps.map((step, index) => (
                                                                <Draggable 
                                                                    key={index.toString()} 
                                                                    draggableId={index.toString()} 
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <li
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            className="flex items-center justify-between p-3 rounded-md bg-slate-50 dark:bg-slate-700"
                                                                        >
                                                                            <div className="flex items-center gap-3">
                                                                                <div {...provided.dragHandleProps} className="text-slate-400">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                                                                    </svg>
                                                                                </div>
                                                                                <span className="text-sm text-slate-700 dark:text-slate-200">
                                                                                    {index + 1}. {step}
                                                                                </span>
                                                                            </div>
                                                                            <div className="flex items-center gap-2">
                                                                                <button
                                                                                    onClick={() => handleEditStep(index)}
                                                                                    className="text-slate-400 hover:text-blue-500 dark:text-slate-500 dark:hover:text-blue-400"
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handleRemoveStep(index)}
                                                                                    className="text-slate-400 hover:text-red-500 dark:text-slate-500 dark:hover:text-red-400"
                                                                                >
                                                                                    <TrashIcon className="h-4 w-4" />
                                                                                </button>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </ul>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        ) : (
                                            <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                                                <p className="text-slate-500 dark:text-slate-400">
                                                    No demo steps added yet. Add steps to create a product demo sequence.
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="mt-6 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            className="px-4 py-2 border border-slate-300 rounded-md dark:border-slate-600 dark:text-slate-300"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white focus:outline-none ${
                                                demoName.trim() && demoSteps.length > 0
                                                    ? "bg-blue-600 hover:bg-blue-700"
                                                    : "bg-blue-400 cursor-not-allowed"
                                            }`}
                                            onClick={handleSave}
                                            disabled={!demoName.trim() || demoSteps.length === 0}
                                        >
                                            {isEditing ? 'Save Changes' : 'Create Demo'}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <ProductDemoStepModal
                isOpen={isStepModalOpen}
                closeModal={() => {
                    setIsStepModalOpen(false);
                    setEditingStepIndex(null);
                }}
                onSave={handleStepSave}
                initialStep={editingStepIndex !== null ? demoSteps[editingStepIndex] : ''}
                isEditing={editingStepIndex !== null}
            />
        </>
    );
} 