import { useEffect, useState, Fragment } from 'react';
import useApi from '../api';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import SchedulingFieldsModal from './SchedulingFieldsModal';
import { Dialog, Transition } from '@headlessui/react';
import SlackNotifications from './actions/SlackNotifications';
import SlackNotificationsModal from './actions/SlackNotificationsModal';
import SlackIcon from '../icons/slack_logo.webp';
import ChiliPiperIcon from '../icons/chili_piper_logo.png';
import CalendlyIcon from '../icons/calendly_logo.png';
import TidyCalIcon from '../icons/tidycal_logo.jpeg';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProductDemoModal from './actions/ProductDemoModal';

interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
    type: string;
}

interface ActionsProps {
    actions: Record<string, any>;
    setActions: (actions: Record<string, any>) => void;
    setNeedsUpdate: (needsUpdate: boolean) => void;
    formFields: any[];
    agentType: string;
}

const LoadingSkeleton = () => (
    <div className="mt-6 space-y-4">
        {[1, 2].map((i) => (
            <div key={i} className="p-4 rounded-lg border border-slate-200 dark:border-slate-700">
                <div className="animate-pulse flex items-center justify-between">
                    <div className="space-y-2">
                        <div className="h-5 bg-slate-200 dark:bg-slate-700 rounded w-32"></div>
                        <div className="h-4 bg-slate-200 dark:bg-slate-700 rounded w-48"></div>
                    </div>
                    <div className="w-6 h-6 bg-slate-200 dark:bg-slate-700 rounded-full"></div>
                </div>
            </div>
        ))}
    </div>
);

const INTEGRATIONS_REQUIRING_MAPPING = ['google-calendar', 'chili-piper'];

export default function Actions({ actions, setActions, setNeedsUpdate, formFields, agentType }: ActionsProps) {
    const { get } = useApi();
    const [calendarIntegrations, setCalendarIntegrations] = useState<Integration[]>([]);
    const [selectedCalendarIntegration, setSelectedCalendarIntegration] = useState<string | null>(
        actions.scheduling?.id || null
    );
    const [isLoading, setIsLoading] = useState(true);
    const [isFieldMappingOpen, setIsFieldMappingOpen] = useState(false);
    const [pendingIntegration, setPendingIntegration] = useState<Integration | null>(null);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [integrationToDisconnect, setIntegrationToDisconnect] = useState<Integration | null>(null);
    const [slackIntegration, setSlackIntegration] = useState<Integration | null>(null);
    const [isSlackModalOpen, setIsSlackModalOpen] = useState(false);
    const [selectedNotificationIntegration, setSelectedNotificationIntegration] = useState<Integration | null>(null);
    const [showSlackDisconnectModal, setShowSlackDisconnectModal] = useState(false);
    const [demos, setDemos] = useState<Array<{ name: string; steps: string[] }>>(
        actions.productDemos || []
    );
    const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
    const [editingDemoIndex, setEditingDemoIndex] = useState<number | null>(null);

    useEffect(() => {
        const fetchIntegrations = async () => {
            try {
                const response = await get('/integrations/');
                const integrations = response.data;

                const slackInt = integrations.find((int: Integration) => int.name === 'slack');
                if (slackInt) {
                    setSlackIntegration(slackInt);
                }

                const calendarApps = ['calendly', 'tidycal', 'google-calendar', 'chili-piper'];
                const filteredIntegrations = integrations.filter((integration: Integration) =>
                    calendarApps.includes(integration.name)
                );
                setCalendarIntegrations(filteredIntegrations);
            } catch (error) {
                console.error('Error fetching integrations:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchIntegrations();
    }, []);

    const handleCardClick = (integration: Integration) => {
        if (selectedCalendarIntegration !== integration.id) {
            if (integration && INTEGRATIONS_REQUIRING_MAPPING.includes(integration.name)) {
                setPendingIntegration(integration);
                setIsFieldMappingOpen(true);
            } else {
                setSelectedCalendarIntegration(integration.id);
                setActions({
                    ...actions,
                    scheduling: {
                        id: integration.id,
                        name: integration.name,
                        details: integration.details
                    }
                });
                setNeedsUpdate(true);
            }
        }
    };

    const handleCheckmarkClick = (e: React.MouseEvent, integration: Integration) => {
        e.stopPropagation();
        if (selectedCalendarIntegration === integration.id) {
            setIntegrationToDisconnect(integration);
            setShowDisconnectModal(true);
        }
    };

    const handleDisconnect = () => {
        setSelectedCalendarIntegration(null);
        setActions({
            ...actions,
            scheduling: null
        });
        setNeedsUpdate(true);
        setShowDisconnectModal(false);
        setIntegrationToDisconnect(null);
    };

    const handleEditMapping = (e: React.MouseEvent, integration: Integration) => {
        e.stopPropagation();
        setPendingIntegration(integration);
        setIsFieldMappingOpen(true);
    };

    const handleFieldMappingSave = (fieldMappings: Record<string, string>) => {
        if (pendingIntegration && Object.keys(fieldMappings).length > 0) {
            setSelectedCalendarIntegration(pendingIntegration.id);
            setActions({
                ...actions,
                scheduling: {
                    id: pendingIntegration.id,
                    name: pendingIntegration.name,
                    details: pendingIntegration.details,
                    fields: fieldMappings
                }
            });
            setNeedsUpdate(true);
        }
        setPendingIntegration(null);
    };

    const handleModalClose = () => {
        setPendingIntegration(null);
        setIsFieldMappingOpen(false);
    };

    const handleNotificationIntegrationClick = (integration: Integration) => {
        setSelectedNotificationIntegration(integration);
        setIsSlackModalOpen(true);
    };

    const handleSlackCheckmarkClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (actions.slack) {
            setShowSlackDisconnectModal(true);
        }
    };

    const handleSlackDisconnect = () => {
        setActions({
            ...actions,
            slack: null
        });
        setNeedsUpdate(true);
        setShowSlackDisconnectModal(false);
    };

    const handleDemoSave = (demo: { name: string; steps: string[] }) => {
        if (editingDemoIndex !== null) {
            const updatedDemos = [...demos];
            updatedDemos[editingDemoIndex] = demo;
            setDemos(updatedDemos);
        } else {
            setDemos([...demos, demo]);
        }

        setActions({
            ...actions,
            productDemos: editingDemoIndex !== null
                ? demos.map((d, i) => i === editingDemoIndex ? demo : d)
                : [...demos, demo]
        });
        setNeedsUpdate(true);
        setEditingDemoIndex(null);
    };

    const handleEditDemo = (index: number) => {

        setEditingDemoIndex(index);
        setTimeout(() => {
            setIsDemoModalOpen(true);
        }, 0);
    };

    const handleRemoveDemo = (index: number) => {
        const updatedDemos = demos.filter((_, i) => i !== index);
        setDemos(updatedDemos);
        setActions({
            ...actions,
            productDemos: updatedDemos
        });
        setNeedsUpdate(true);
    };

    return (
        <div className="mt-8 space-y-4">
            {agentType !== 'product' && (
                <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                    <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                        Scheduling
                    </label>
                    <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                        Configure calendar integrations to allow your agent to schedule meetings.
                    </label>

                    {isLoading ? (
                        <LoadingSkeleton />
                    ) : (
                        <div className="mt-6 space-y-4">
                            {calendarIntegrations.map((integration) => (
                                <div
                                    key={integration.id}
                                    onClick={() => handleCardClick(integration)}
                                    className={clsx(
                                        'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                        selectedCalendarIntegration === integration.id
                                            ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                                            : 'border-slate-200 hover:border-blue-300 dark:border-slate-700 dark:hover:border-blue-500'
                                    )}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <img
                                                src={
                                                    integration.name === 'chili-piper' ? ChiliPiperIcon :
                                                        integration.name === 'calendly' ? CalendlyIcon :
                                                            integration.name === 'tidycal' ? TidyCalIcon :
                                                                undefined
                                                }
                                                alt={`${integration.name} Icon`}
                                                className="h-8"
                                            />
                                            <div>
                                                <h3 className="text-md font-medium text-slate-900 dark:text-slate-100">
                                                    {integration.name === 'chili-piper' ? 'Chili Piper' :
                                                        integration.name === 'calendly' ? 'Calendly' :
                                                            integration.name === 'tidycal' ? 'TidyCal' :
                                                                integration.name.charAt(0).toUpperCase() + integration.name.slice(1)}
                                                </h3>
                                                <p className="text-sm text-slate-500 dark:text-slate-400 mt-1">
                                                    {integration.details?.calendly_url && (
                                                        <span className="block">{integration.details.calendly_url}</span>
                                                    )}
                                                    {integration.details?.tidy_cal_url && (
                                                        <span className="block">{integration.details.tidy_cal_url}</span>
                                                    )}
                                                    {integration.details?.domain_name && (
                                                        <span className="block">{integration.details.domain_name}</span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            {selectedCalendarIntegration === integration.id &&
                                                INTEGRATIONS_REQUIRING_MAPPING.includes(integration.name) && (
                                                    <button
                                                        onClick={(e) => handleEditMapping(e, integration)}
                                                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                        </svg>
                                                    </button>
                                                )}
                                            <button
                                                onClick={(e) => handleCheckmarkClick(e, integration)}
                                                className={clsx(
                                                    'w-6 h-6 rounded-full border-2',
                                                    selectedCalendarIntegration === integration.id
                                                        ? 'border-blue-500 bg-blue-500'
                                                        : 'border-slate-300 dark:border-slate-600'
                                                )}
                                            >
                                                {selectedCalendarIntegration === integration.id && (
                                                    <svg className="w-full h-full text-white p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                    </svg>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!isLoading && calendarIntegrations.length === 0 && (
                                <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                                    <p className="text-slate-500 dark:text-slate-400 mb-4">
                                        No calendar integrations found. Add one to get started.
                                    </p>
                                    <Link
                                        to="/integrations"
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm transition-colors duration-200"
                                    >
                                        <svg
                                            className="w-4 h-4 mr-2"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 4v16m8-8H4"
                                            />
                                        </svg>
                                        Add Integration
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <Transition appear show={showDisconnectModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowDisconnectModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-slate-900 dark:text-slate-100"
                                    >
                                        Delete Action
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-slate-500 dark:text-slate-400">
                                            Are you sure you want to delete {integrationToDisconnect?.name}?
                                        </p>
                                    </div>

                                    <div className="mt-6 flex justify-end gap-3">
                                        <button
                                            onClick={() => setShowDisconnectModal(false)}
                                            className="px-4 py-2 border border-slate-300 rounded-md dark:border-slate-600 dark:text-slate-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleDisconnect}
                                            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <SchedulingFieldsModal
                isOpen={isFieldMappingOpen}
                closeModal={handleModalClose}
                formFields={formFields}
                onSave={handleFieldMappingSave}
                existingMappings={actions.scheduling?.fields || {}}
                integrationName={pendingIntegration?.name || ''}
            />

            <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                    Notifications
                </label>
                <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                    Send notifications to external channels.
                </label>

                {isLoading ? (
                    <LoadingSkeleton />
                ) : (
                    <div className="mt-6 space-y-4">
                        {slackIntegration ? (
                            <div
                                onClick={() => handleNotificationIntegrationClick(slackIntegration)}
                                className={clsx(
                                    'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                    actions.slack?.configs?.length > 0
                                        ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                                        : 'border-slate-200 hover:border-blue-300 dark:border-slate-700 dark:hover:border-blue-500'
                                )}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img src={SlackIcon} alt="Slack Icon" className="h-8" />
                                        <div>
                                            <h3 className="text-md font-medium text-slate-900 dark:text-slate-100">
                                                Slack
                                            </h3>
                                            <p className="text-sm text-slate-500 dark:text-slate-400 mt-1">
                                                {actions.slack?.configs?.length > 0
                                                    ? `${actions.slack.configs.length} channel${actions.slack.configs.length === 1 ? '' : 's'} configured`
                                                    : 'Not configured'}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={handleSlackCheckmarkClick}
                                        className={clsx(
                                            'w-6 h-6 rounded-full border-2',
                                            actions.slack?.configs?.length > 0
                                                ? 'border-blue-500 bg-blue-500'
                                                : 'border-slate-300 dark:border-slate-600'
                                        )}
                                    >
                                        {actions.slack?.configs?.length > 0 && (
                                            <svg className="w-full h-full text-white p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                                <p className="text-slate-500 dark:text-slate-400 mb-4">
                                    No notification integrations found. Add Slack to get started.
                                </p>
                                <Link
                                    to="/integrations"
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm transition-colors duration-200"
                                >
                                    <svg
                                        className="w-4 h-4 mr-2"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 4v16m8-8H4"
                                        />
                                    </svg>
                                    Add Integration
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <SlackNotificationsModal
                isOpen={isSlackModalOpen}
                closeModal={() => setIsSlackModalOpen(false)}
                integration={selectedNotificationIntegration}
                value={actions.slack}
                onSave={(value) => {
                    setActions({
                        ...actions,
                        slack: value
                    });
                    setNeedsUpdate(true);
                }}
            />

            <Transition appear show={showSlackDisconnectModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setShowSlackDisconnectModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-slate-900 dark:text-slate-100"
                                    >
                                        Delete Action
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-slate-500 dark:text-slate-400">
                                            Are you sure you want to remove Slack notifications?
                                        </p>
                                    </div>

                                    <div className="mt-6 flex justify-end gap-3">
                                        <button
                                            onClick={() => setShowSlackDisconnectModal(false)}
                                            className="px-4 py-2 border border-slate-300 rounded-md dark:border-slate-600 dark:text-slate-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleSlackDisconnect}
                                            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <div className="p-6 bg-white shadow-sm border rounded-lg dark:bg-slate-800 dark:border-slate-700/50">
                <label className="block text-xl font-semibold leading-6 text-slate-900 dark:text-slate-50">
                    Product Demos
                </label>
                <label className="block text-sm font-light leading-6 text-slate-500 mt-1 dark:text-slate-200">
                    Create demo sequences for the AI to follow when demonstrating your product.
                </label>

                <div className="mt-6 space-y-4">
                    <button
                        onClick={() => setIsDemoModalOpen(true)}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm transition-colors duration-200"
                    >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Create Demo
                    </button>

                    {demos.length > 0 ? (
                        <div className="space-y-4">
                            {demos.map((demo, index) => (
                                <div key={index} className="p-4 rounded-lg border border-slate-200 dark:border-slate-700">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-lg font-medium text-slate-900 dark:text-slate-100">
                                            {demo.name}
                                        </h3>
                                        <div className="flex items-center gap-2">
                                            <button
                                                onClick={() => handleEditDemo(index)}
                                                className="text-slate-400 hover:text-blue-500 dark:text-slate-500 dark:hover:text-blue-400"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() => handleRemoveDemo(index)}
                                                className="text-slate-400 hover:text-red-500 dark:text-slate-500 dark:hover:text-red-400"
                                            >
                                                <TrashIcon className="h-4 w-4" />
                                            </button>
                                        </div>
                                    </div>
                                    <ol className="space-y-2">
                                        {demo.steps.map((step, stepIndex) => (
                                            <li key={stepIndex} className="text-sm text-slate-600 dark:text-slate-300">
                                                {stepIndex + 1}. {step}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                            <p className="text-slate-500 dark:text-slate-400">
                                No demos created yet. Create a demo to help your AI demonstrate your product.
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <ProductDemoModal
                isOpen={isDemoModalOpen}
                closeModal={() => {
                    setIsDemoModalOpen(false);
                    setEditingDemoIndex(null);
                }}
                onSave={handleDemoSave}
                initialDemo={(() => {
                    const demo = editingDemoIndex !== null ? demos[editingDemoIndex] : undefined;
                    return demo;
                })()}
                isEditing={editingDemoIndex !== null}
            />
        </div>
    );
}
