import React, { useState, useContext, useEffect } from 'react';
import useApi from './api';
import logo from '../../logo.webp';
import logo_symbol from '../logo_symbol.png';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from './config/Toast';
import { log } from 'console';
import AgentFromAI from './AgentFromAI';
import { set } from 'lodash';
import { UserInterface } from './types';
import { useUser } from './user/UserContext';
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import UserCircle from './user/UserCircle';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { conLog } from './utils/conLog';
import UsageAnalytics from './analytics/UsageAnalytics';
import Sessions from './agents/Sessions';
import WeeklyLeads from './analytics/WeeklyLeads';
import { AimdocComponentContext, AimdocTaskDefinition, startTask } from '@aimdoc/sdk-react';

const Dashboard = () => {

    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [userProfile, setUserProfile] = useState<UserInterface>();
    const { user, organization, refreshUser } = useUser();
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [leads, setLeads] = useState([]);
    const [agents, setAgents] = useState<[] | undefined>(undefined);
    const [initWalkthroughDone, setInitWalkthroughDone] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }


    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await getAgents();
            getDocuments();
        };
        fetchData();
        // startTask('setup-assistance');
    }, []);

    useEffect(() => {
        if (!agents) return;
        if (agents.length === 0 && user && (initWalkthroughDone === true || user?.walkthrough_complete === true)) openModal();
    }, [agents, userProfile, initWalkthroughDone]);

    const getDocuments = async () => {
        try {
            const data = await get('/documentation/?this_week=true');
            setDocuments(data.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const getAgents = async () => {
        try {
            const response = await get('/agents/');
            setAgents(response.data);
        } catch (error) {
            console.error('Error fetching agents:', error);
        }
    };

    useEffect(() => {
        const updateInvitedUser = async () => {
            if (user && user.was_invited && !user.walkthrough_complete) {
                try {
                    await put(`/users/${user.id}/walkthrough-complete/`, {});
                    refreshUser();
                } catch (error) {
                    console.error('Error updating walkthrough status:', error);
                }
            }
        };

        updateInvitedUser();
    }, [user]);

    return (
        <div className="flex flex-col w-full px-4 bg-gradient-to-br from-blue-100 to-blue-500 overflow-y-auto scrollbar-thin pb-16 dark:from-slate-900 dark:to-slate-700">
            <AimdocComponentContext
                componentDescription="You are currently on Aimdoc's Dashboard. This gives an overview of new leads, usage and active sessions."
                componentName="Dashboard"
                path="/"
            />
            <AimdocTaskDefinition
                id="setup-assistance"
                name="Setup Assistance"
                description="Offer assistance to the user."
                steps={[
                    "Please ask the user if they need help setting up Aimdoc.",
                ]}
            />
            {user && user.walkthrough_complete !== true && user.was_invited !== true && <AgentFromAI setComplete={setInitWalkthroughDone} user={user} />}
            <div className="flex flex-row w-full justify-center mt-8">
                <UsageAnalytics organization={organization} />
                <WeeklyLeads />
            </div>
            <div className="flex flex-row justify-center items-center mt-16">
                <h1 className="text-4xl font-semibold text-center items-center text-blue-900 mr-2 dark:text-slate-200">Active Sessions</h1>
            </div>
            <div className="flex w-full justify-center">
                <div className="flex flex-col w-full max-w-7xl">
                    <Sessions dashboard={true} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;