import React, { useState, useEffect, useRef } from 'react';
import Message from '../documentation/Message';
import Spinner from '../documentation/Spinner';
import useApi from '../api';
import { MessageType } from '../types';
import { useNavigate } from 'react-router-dom';
import config from '../../config.json';
import { toast} from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../user/UserContext';
import { formatDate } from '../utils/dateFormatter';
import { debounce } from 'lodash';
interface ChatProps {
    chatId: string | null;
    status: string;
    setStatus: (status: string) => void;
    leadId: string | null;
    setLeadId: (leadId: string) => void;
}

interface GroupedMessage {
    url: string;
    messages: MessageType[];
}

const backendUrl = config.env === 'development' ? 'http://localhost:8000' : process.env.REACT_APP_API_URL as string;

const Chat: React.FC<ChatProps> = ({ chatId, status, setStatus, leadId, setLeadId }) => {
    const [loading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [conversationId, setConversationId] = useState('');
    const [conversation, setConversation] = useState<any>(null);
    const [assistantResponse, setAssistantResponse] = useState('');
    const [takeOverChat, setTakeOverChat] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isVisitorTyping, setIsVisitorTyping] = useState(false);
    const [isAiResponding, setIsAiResponding] = useState(false);
    const { get, post, connectWebSocket } = useApi();
    const { user} = useUser();

    const chatRef = useRef<HTMLDivElement | null>(null);
    const socketRef = useRef<WebSocket | null>(null);
    const takeOverChatRef = useRef<boolean>(takeOverChat);
    const shouldReconnectRef = useRef(true);

    useEffect(() => {
        takeOverChatRef.current = takeOverChat;
    }, [takeOverChat]);


    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    const sendTypingStatus = (typing: boolean) => {
        if (socketRef.current) {
            socketRef.current.send(JSON.stringify({
                type: 'agent_typing',
                is_typing: typing,
                conversation_id: conversationId
            }));
        }
    };

    const debouncedSendTypingStatus = useRef(
        debounce((typing: boolean) => sendTypingStatus(typing), 300)
    ).current;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setUserInput(newValue);

        if (newValue && !isTyping) {
            setIsTyping(true);
            sendTypingStatus(true);
        } else if (!newValue && isTyping) {
            setIsTyping(false);
            sendTypingStatus(false);
        } else {
            debouncedSendTypingStatus(!!newValue);
        }
    };

    useEffect(() => {
        return () => {
            if (isTyping) {
                sendTypingStatus(false);
            }
            debouncedSendTypingStatus.cancel();
        };
    }, [isTyping, debouncedSendTypingStatus]);

    useEffect(() => {
        scrollToBottom();
        setShouldScroll(false);
    }, [shouldScroll]);

    const handleTakeOverChat = () => {
        if (status !== 'active') {
            toast.error('Session is not active', toastConfig);
            return;
        }

        if (socketRef.current) {
            setTakeOverChat(true);
            socketRef.current.send(JSON.stringify({
                type: 'take_over_chat',
                conversation_id: conversationId
            }));
        }
    };

    const handleHandBackChat = () => {
        if (socketRef.current) {
            setTakeOverChat(false);
            setIsAiResponding(true);
            socketRef.current.send(JSON.stringify({
                type: 'hand_back_chat',
                conversation_id: conversationId
            }));
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversationId, assistantResponse]);

    const sendMessage = () => {
        if (socketRef.current && userInput.trim()) {
            setIsTyping(false);
            const message = {
                type: 'message',
                content: userInput,
                role: 'assistant',
                created_at: new Date().toISOString(),
                human_agent: user
            };
            setShouldScroll(true);
            socketRef.current.send(JSON.stringify(message));

            setMessages((prevMessages) => [...prevMessages, message]);
            setUserInput('');
        }
    };

    useEffect(() => {
        const getConversation = async (chatId: string) => {
            try {
                setLoading(true);
                const response = await get(`/sessions/${chatId}`);
                setMessages(response.data.messages);
                setConversationId(response.data.id);
                setConversation(response.data);
                setStatus(response.data.status);
                setTakeOverChat(response.data.is_human_agent);
            } catch (error) {
                console.error('Error fetching conversation:', error);
            }
            setLoading(false);
        };

        if (chatId) {
            getConversation(chatId);
        }

        if (chatId && !socketRef.current) {
            const initializeWebSocket = async () => {
                try {
                    const ws = await connectWebSocket(`/ws/${chatId}?role=assistant`);
                    if (!ws) {
                        console.error('WebSocket connection failed');
                        return;
                    }
                    socketRef.current = ws;

                    ws.onmessage = (event) => {
                        try {
                            const data = JSON.parse(event.data);
                            if (data.type === 'message') {
                                if (data.role === 'user') {
                                    const newMessage: MessageType = {
                                        id: data.id,
                                        role: data.role,
                                        content: data.content,
                                        created_at: new Date().toISOString(),
                                        message_metadata: data.message_metadata
                                    };
                                    setMessages((prevMessages) => [...prevMessages, newMessage]);
                                    setIsVisitorTyping(false);

                                } else if ((data.role === 'assistant' || data.role === 'system') && !takeOverChatRef.current) {
                                    const newMessage: MessageType = {
                                        role: data.role,
                                        content: data.content,
                                        created_at: new Date().toISOString(),
                                    };
                                    setMessages((prevMessages) => [...prevMessages, newMessage]);
                                }
                            }
                            if (data.type === 'assistant' && data.final_message) {
                                const newMessage: MessageType = {
                                    role: 'assistant',
                                    content: data.final_message,
                                    created_at: new Date().toISOString(),
                                };
                                setMessages((prevMessages) => [...prevMessages, newMessage]);
                            }
                            if (data.type === 'take_over_chat') {
                                setTakeOverChat(true);
                                setMessages((prevMessages) => [...prevMessages, data]);
                            }
                            if (data.type === 'hand_back_chat') {
                                setTakeOverChat(false);
                                setMessages((prevMessages) => [...prevMessages, data]);
                            }
                            if (data.type === 'status') {
                                setStatus(data.status);
                                if (data.status === 'inactive') {
                                    toast.info('Visitor closed the chat', toastConfig);
                                }
                            }
                            if (data.type === 'visitor_typing') {
                                setIsVisitorTyping(data.is_typing);
                                setShouldScroll(true);
                            }
                            if (data.type === 'ai_responding') {
                                setIsAiResponding(data.responding);
                                setShouldScroll(true);
                            }
                        } catch (error) {
                            console.error('Error parsing WebSocket message:', error);
                        }
                    };

                    ws.onopen = () => {
                        setReconnectAttempts(0);
                    };

                    ws.onclose = (event) => {
                        if (shouldReconnectRef.current) {
                            const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                            setTimeout(() => {
                                setReconnectAttempts((prev) => prev + 1);
                                initializeWebSocket();
                            }, delay);
                        }
                    };

                    ws.onerror = (error) => {
                        console.error('WebSocket error:', error);
                    };
                } catch (error) {
                    console.error('Failed to establish WebSocket connection:', error);
                    if (shouldReconnectRef.current) {
                        const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                        setTimeout(() => {
                            setReconnectAttempts((prev) => prev + 1);
                            initializeWebSocket();
                        }, delay);
                    }
                }
            };

            initializeWebSocket();
        }
        return () => {
            shouldReconnectRef.current = false;
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [chatId]);

    useEffect(() => {
        return () => {
            shouldReconnectRef.current = false;
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (status === 'active') {
            toast.info('Visitor is active', toastConfig);
        }
    }, [status]);

    const normalizeUrl = (url: string): string => {
        try {
            const urlObject = new URL(url);
            return `${urlObject.protocol}//${urlObject.hostname}${urlObject.pathname}`;
        } catch (error) {
            console.error('Error parsing URL:', error);
            return url;
        }
    };
    
    const groupMessagesByUrl = (messages: MessageType[]): GroupedMessage[] => {
        const grouped: GroupedMessage[] = [];
        let currentGroup: GroupedMessage | null = null;
    
        messages.forEach((message: MessageType) => {
            const fullUrl = message.message_metadata?.parent_window_url;
            
            const normalizedUrl = fullUrl 
                ? normalizeUrl(fullUrl)
                : currentGroup?.url || 'Unknown URL';
    
            if (!currentGroup || currentGroup.url !== normalizedUrl) {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = { url: normalizedUrl, messages: [] };
            }
    
            currentGroup.messages.push(message);
        });
    
        if (currentGroup) {
            grouped.push(currentGroup);
        }
    
        return grouped;
    };

    const sortedMessages = [...messages].sort((a, b) => {
    const dateA = new Date(a.created_at.endsWith('Z') ? a.created_at : `${a.created_at}Z`);
    const dateB = new Date(b.created_at.endsWith('Z') ? b.created_at : `${b.created_at}Z`);
    return dateA.getTime() - dateB.getTime();
});

const groupedMessages = groupMessagesByUrl(sortedMessages);

return (
    <div className="relative flex flex-col w-full bg-slate-100 z-20 transition-all duration-20 dark:bg-slate-800">
        <div
            ref={chatRef}
            className="flex flex-col h-full w-full grow items-center overflow-y-auto scrollbar-thin"
        >
            <div className="p-4 w-full justify-center grow items-start space-y-4">
                {groupedMessages.map((group, groupIndex) => (
                    <div key={groupIndex} className="flex flex-col mb-6 rounded-lg border border-slate-200 bg-slate-50 dark:border-slate-700 dark:bg-slate-900/50 p-4">
                        <div className="text-center font-semibold text-lg mb-4 text-slate-600 dark:text-slate-400">
                            {group.url}
                        </div>
                        {group.messages.map((message, index) => (
                            <div key={index} className="flex flex-col">
                                {(message.role === 'user' ||
                                    message.role === 'assistant') && (
                                    <Message
                                        role={message.role}
                                        content={message.content}
                                        created_at={message.created_at}
                                        human_agent={message.human_agent}
                                        conversationId={conversationId}
                                    />
                                )}
                                {message.role === 'system' && (
                                    <div>
                                        {message.type === 'take_over_chat' && (
                                            <div className="flex flex-col justify-center items-center text-sm font-medium text-green-500 mt-2 mb-2">
                                                <div className="flex flex-row mr-2 bg-green-50 items-center px-2 py-1 rounded-lg border border-green-200 dark:bg-green-100 dark:border-green-500 dark:text-green-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                                    </svg>
                                                    <div className="ml-2">
                                                        {`${message.agent_name ? message.agent_name : 'Agent'} has taken over the chat.`}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    {formatDate(message.created_at)}
                                                </div>
                                            </div>
                                        )}
                                        {message.type === 'hand_back_chat' && (
                                            <div className="flex flex-col justify-center items-center text-sm font-medium text-amber-500 mt-2 mb-2">
                                                <div className="flex flex-row mr-2 bg-amber-100 items-center px-2 py-1 rounded-lg border border-amber-200 dark:bg-amber-100 dark:border-amber-500 dark:text-amber-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
                                                    </svg>

                                                    <div className="ml-2">
                                                        {`${message.agent_name ? message.agent_name : 'Agent'} handed the chat back to Aimdoc AI.`}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    {formatDate(message.created_at)}
                                                </div>
                                            </div>
                                        )}
                                        {message.type === 'agent_closed' && (
                                            <div className="flex flex-col justify-center items-center text-sm font-medium text-amber-500 mt-2 mb-2">
                                                <div className="flex flex-row mr-2 bg-amber-100 items-center px-2 py-1 rounded-lg border border-amber-200 dark:bg-amber-100 dark:border-amber-500 dark:text-amber-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
                                                    </svg>

                                                    <div className="ml-2">
                                                        {`${message.agent_name ? message.agent_name : 'Agent'} left and handed the chat back to Aimdoc AI.`}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    {formatDate(message.created_at)}
                                                </div>
                                            </div>
                                        )}
                                        {message.type === 'click_event' && (
                                            <div>

                                                <div className="flex flex-row justify-center items-center text-sm font-medium text-slate-500 mt-2 mb-2 dark:text-slate-300">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
                                                        <path fillRule="evenodd" d="M12 1.5a.75.75 0 0 1 .75.75V4.5a.75.75 0 0 1-1.5 0V2.25A.75.75 0 0 1 12 1.5ZM5.636 4.136a.75.75 0 0 1 1.06 0l1.592 1.591a.75.75 0 0 1-1.061 1.06l-1.591-1.59a.75.75 0 0 1 0-1.061Zm12.728 0a.75.75 0 0 1 0 1.06l-1.591 1.592a.75.75 0 0 1-1.06-1.061l1.59-1.591a.75.75 0 0 1 1.061 0Zm-6.816 4.496a.75.75 0 0 1 .82.311l5.228 7.917a.75.75 0 0 1-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 0 1-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 0 1-1.247-.606l.569-9.47a.75.75 0 0 1 .554-.68ZM3 10.5a.75.75 0 0 1 .75-.75H6a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 10.5Zm14.25 0a.75.75 0 0 1 .75-.75h2.25a.75.75 0 0 1 0 1.5H18a.75.75 0 0 1-.75-.75Zm-8.962 3.712a.75.75 0 0 1 0 1.061l-1.591 1.591a.75.75 0 1 1-1.061-1.06l1.591-1.592a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                    </svg>
                                                    <div className="ml-2 whitespace-pre-wrap break-all">
                                                        {message.content}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-center items-center text-xs font-medium text-slate-400 mt-2 mb-2 dark:text-slate-400">
                                                    {formatDate(message.created_at)}
                                                </div>
                                            </div>
                                        )}
                                        {message.type === 'user' && (
                                            <div className="flex flex-col justify-center items-center text-sm font-medium text-slate-500 mt-2 mb-2">
                                                <div className="flex flex-row mr-2 bg-slate-100 items-center px-2 py-1 rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 dark:text-slate-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clipRule="evenodd" />
                                                        <path d="m10.076 8.64-2.201-2.2V4.874a.75.75 0 0 0-.364-.643l-3.75-2.25a.75.75 0 0 0-.916.113l-.75.75a.75.75 0 0 0-.113.916l2.25 3.75a.75.75 0 0 0 .643.364h1.564l2.062 2.062 1.575-1.297Z" />
                                                        <path fillRule="evenodd" d="m12.556 17.329 4.183 4.182a3.375 3.375 0 0 0 4.773-4.773l-3.306-3.305a6.803 6.803 0 0 1-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 0 0-.167.063l-3.086 3.748Zm3.414-1.36a.75.75 0 0 1 1.06 0l1.875 1.876a.75.75 0 1 1-1.06 1.06L15.97 17.03a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                    </svg>

                                                    <div className="ml-2">
                                                        {message.content}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    {formatDate(message.created_at)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
                {isVisitorTyping && (
                    <Message
                        role="user"
                        content={assistantResponse}
                        isVisitorTyping={isVisitorTyping}
                        conversationId={conversationId}
                    />
                )}
                {isAiResponding && (
                    <Message
                        role="assistant"
                        content={assistantResponse}
                        isVisitorTyping={isAiResponding}
                        conversationId={conversationId}
                    />
                )}
                {loading && <Spinner />}
            </div>
        </div>
            <div className="sticky bottom-0 z-20 shadow-l bg-slate-50 border-t p-6 dark:bg-slate-800 dark:border-none">
                {!takeOverChat && status === 'active' && (
                    <button
                        onClick={() => handleTakeOverChat()}
                        disabled={isAiResponding}
                        className="hover:cursor-pointer w-full hover:bg-slate-200 text-xl font-medium leading-6 text-slate-600 px-4 py-2 text-center border bg-slate-100 rounded-md dark:bg-slate-700 dark:text-slate-100 dark:border-none dark:hover:bg-slate-600"
                    >
                        Take Over Chat
                    </button>
                )}
                {takeOverChat && status === 'active' && (
                    <div
                        onClick={() => handleHandBackChat()}
                        className="hover:cursor-pointer hover:bg-slate-200 text-xl font-medium leading-6 text-slate-600 mb-6 px-4 py-2 text-center border bg-slate-100 rounded-md dark:bg-slate-700 dark:text-slate-100 dark:border-none dark:hover:bg-slate-600"
                    >
                        Hand Back to AI
                    </div>
                )}
                {takeOverChat && status === 'active' && (
                    <div className="mx-auto flex flex-row">
                        <div className="relative flex w-full h-12 rounded-xl bg-white border dark:bg-slate-800 dark:border-none">
                            <input
                                className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-l-xl focus:ring-blue-200 focus:border-transparent dark:text-slate-50 dark:bg-slate-900 dark:focus:ring-1 dark:focus:ring-slate-700 dark:border dark:border-slate-700"
                                type="text"
                                id="search"
                                placeholder="Type your message..."
                                value={userInput}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        sendMessage();
                                    }
                                }}
                            />

                            <button
                                onClick={sendMessage}
                                disabled={!takeOverChat}
                                className={`grid place-items-center h-full w-12 text-slate-300 bg-blue-200 hover:bg-blue-500 text-white rounded-r-xl ${
                                    !takeOverChat
                                        ? 'disabled:opacity-50 disabled:pointer-events-none'
                                        : ''
                                } `}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                )}
            </div>
    </div>
);
};


export default Chat;